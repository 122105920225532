<template>
  <div class="position-relative">
    <b-form-group
      :label="label"
      :label-for="id"
      :id="`${id}Group`"
      :state="state != undefined ? state : null"
    >
      <multiselect
        :id="id"
        v-model="model"
        :label="textField"
        :track-by="valueField"
        :options="options"
        :allowEmpty="true"
        :show-labels="false"
        :placeholder="placeholder"
        :limit="3"
        :hide-selected="false"
        :searchable="true"
        :multiple="multiple"
        :close-on-select="!multiple"
        :disabled="disable"
        :input="inputValue()"
      >
        <span slot="noResult"> Aramanıza uygun kayıt bulunamadı. </span>
      </multiselect>
    </b-form-group>
  </div>
</template>

<script>
import multiselect from 'vue-multiselect';

export default {
  props: {
    id: {
      type: String,
      default: new Date().getTime().toString(),
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    valueField: {
      type: String,
      default: 'value',
    },
    textField: {
      type: String,
      default: 'text',
    },
    defaultValue: { type: String | Array, default: '' },
    options: Array | Object,
    state: { type: Boolean, default: undefined },
    multiple: { type: Boolean | String, default: true },
    changeValues: { type: String | Array, default: '' },
  },
  name: 'MultiSelect',
  data() {
    return {
      model: '',
      disable: true,
    };
  },
  created() {
    this.setDisabledStatus();
    this.setDefaultValue();
  },
  methods: {
    setDefaultValue() {
      this.model = this.defaultValue != '' ? this.defaultValue : '';
    },
    inputValue() {
      this.$emit('input', this.model);
    },
    setDisabledStatus() {
      this.disable = this.options.length < 1;
    },
  },
  watch: {
    defaultValue() {
      this.setDefaultValue();
    },
    options() {
      this.setDisabledStatus();
    },
    value(newVal) {
      this.model = newVal;
    },
    changeValues() {
      this.model = this.changeValues;
    },
  },
  components: {
    multiselect,
  },
};
</script>

<style lang="scss" scoped>
.loadingIcon {
  position: absolute;
  font-size: 14px;
  top: 45px;
  right: 30px;
  animation: rotation 4s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
